<template>  
    <div class="row">
        <div class="col-12 order-1 order-sm-0">
        <highcharts class="packedbubble" :options="graphOptions" v-if="allEmpresarial.length > 0"></highcharts>
        <b-spinner variant="primary" label="Spinning" v-if="allEmpresarial.length == 0"></b-spinner>        
        </div>  
    </div>
</template>
  
  
  
  <script>
  
    import { Chart } from 'highcharts-vue'
    import Highcharts from "highcharts"
    import more from 'highcharts/highcharts-more'
    import streamgraph from 'highcharts/modules/streamgraph'
    import { mapState } from 'vuex'
    import { map, orderBy } from 'lodash'
  
    more(Highcharts)
    streamgraph(Highcharts)
  
    export default {
      name: 'ViolenciaEmpresarialChart',  
      props: {
        dataset: Array
      },
      components: {
        highcharts: Chart
      },
      data() {
        return {
          fieldSelected: 'anio_aprob',
          categoriesList: []
        }
      },
      watch: {
        allEmpresarial() {
          this.categoriesList = map(orderBy(this.graphOptions.series[0].data, 'y', 'desc'), 'name')
        }
      },
      computed: {
        ...mapState({        
          allEmpresarial: state => state.ataques.allEmpresarial
        }),
        graphOptions() {
          return {
            chart: {
              type: 'pie'
            },
            dataSorting: {
              enabled: true,
              sortKey: 'y'
            },
            credits: {
              enabled: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
              categories: this.categoriesList,
            },
            legend: {
              enabled: true,
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f}%'
                }
              }
            },
            tooltip: {
              useHTML: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f}%'
            },
            series: [{
              name: "Ataques cometidos",
              dataSorting: {
                enabled: true
              },
              data: this.allEmpresarial
            }]
          }
        },          
      }   
    }
  </script>