<template>

    <div class="container">
		<div class="row">
			<div class="col-md-12 col-12">
				<div class="mapa-resumen my-5">
        </div>			
			</div>
		</div>
		<div class="row">		
      <div class="col-md-12 col-12">
        <div class="card">
            <div class="mapa-resumen p-3">
              <h3>Víctimas mortales en proporción al número de días de gestión </h3>
            </div>
            <div class="row card-body">
            <div class="col-md-12">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="dias-tab" data-bs-toggle="tab" data-bs-target="#dias-tab-pane" type="button" role="tab" aria-controls="dias-tab-pane" aria-selected="true">Por días de gobierno</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="total-tab" data-bs-toggle="tab" data-bs-target="#total-tab-pane" type="button" role="tab" aria-controls="total-tab-pane" aria-selected="false">Total</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active p-3" id="dias-tab-pane" role="tabpanel" aria-labelledby="dias-tab" tabindex="0">
                  <highcharts class="packedbubble" :options="graphOptionsDias" v-if="dataColumns.length > 0"></highcharts>
                </div>
                <div class="tab-pane fade p-3" id="total-tab-pane" role="tabpanel" aria-labelledby="total-tab" tabindex="0">
                  <highcharts class="packedbubble" :options="graphOptions" v-if="dataColumns.length > 0"></highcharts>
                </div>
              </div>

              
            </div>
        </div> 

				<div class="note">
					<p>Más allá de las responsabilidades personales la muerte de civiles por acción de las fuerzas del orden y los asesinatos de personas defensoras se relacionan directamente con el incumplimiento de obligaciones estatales en materia de derechos humanos. Por ello es importante hacer un seguimiento de la evolución de estos fenómenos que nos permita comparar entre los diferentes gobiernos, considerando el número de víctimas mortales en proporción al número de días de gestión.</p>
				</div>
			</div>
		</div>
		
		
    </div>

	</div>
</template>

<style scoped>
  .item-ataques {
    display: inline-block;
    border-radius: 50%;
    background: #000;
    width: 5px;
    height: 5px;
  }

 .presi-name {
  font-weight: 800;
  border-bottom: dotted 1px; 
 } 

 .note {
    padding: 15px;
    font-size: 13px;
 }
 
 .btn {
  border-radius: 0;
 }

 .leyenda {
    font-family: Arial, sans-serif;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .leyenda ul {
    list-style: none;
    padding: 0;
  }

  .leyenda li {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .leyenda .color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 3px;
  }

  .leyenda .opcion {
    display: inline-block;
    font-size: 16px;
    width: 16px;
    min-height: 16px;    
  }

</style>

<script>
  
  import { Chart } from 'highcharts-vue'
  import Highcharts from "highcharts"
  import more from 'highcharts/highcharts-more'
  import streamgraph from 'highcharts/modules/streamgraph'
  import { mapState } from 'vuex'  
  import { map } from 'lodash'

  more(Highcharts)
  streamgraph(Highcharts)

export default {
    name: 'RankingAsesinatos',
    props: {
      dataset: Array
    },
    components: {
      highcharts: Chart
    },
    data() {
      return {
        fieldSelected: 'ataque_name',
        categoriesList: [],
      }
    },
    created() {
    },
    mounted() {
      this.$store.dispatch('ataques/getRankingData')
      this.categoriesList = map(this.rankingData, 'name')
    },
    watch: {
      rankingData(value) {
        this.categoriesList = map(value, 'name')
      }
    },
    methods: {
      days_between(date_from, date_to) {
        const oneDay = 24 * 60 * 60 * 1000
        const firstDate = new Date(date_from)
        const secondDate = new Date(date_to)
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))

        return diffDays
      }
    },
    computed: {      
      ...mapState({                
        rankingData: state => state.ataques.rankingData,        
      }),  
      totales() {
        return map(this.rankingData, 'total_asesinados')
      },
      estatales() {
        return map(this.rankingData, 'asesinados_gobierno')
      },
      civiles() {
        return map(this.rankingData, 'asesinados_civiles')
      },
      dias_totales() {
        return map(this.rankingData, item => {
          const diff = this.days_between(item.from, item.to)
          return parseFloat((item.total_asesinados / diff).toFixed(3))
        })
      },
      dias_estatales() {
        return map(this.rankingData, item => {
          const diff = this.days_between(item.from, item.to)
          return parseFloat((item.asesinados_gobierno / diff).toFixed(3))
        })
      },
      dias_civiles() {
        return map(this.rankingData, item => {
          const diff = this.days_between(item.from, item.to)
          return parseFloat((item.asesinados_civiles / diff).toFixed(3))
        })
      },
      dataColumns() {
        return [{
            name: 'Total de asesinatos',
            data: this.totales
          }, {
            name: 'Por agentes estatales',
            data: this.estatales
          }, {
            name: 'Por civiles',
            data: this.civiles
        }]
      },
      dataColumnsDias() {
        return [{
            name: 'Total de asesinatos por día',
            data: this.dias_totales
          }, {
            name: 'Asesinatos por agentes estatales por día',
            data: this.dias_estatales
          }, {
            name: 'Asesinatos cometidos por civiles por día',
            data: this.dias_civiles
        }]
      },
      graphOptions() {
        return {
          chart: {
            type: 'column'
          },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          xAxis: {
              categories: this.categoriesList,
              crosshair: true,
              accessibility: {
                  description: 'Ataques a defensores de DDHH'
              }
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            shared: true,
            useHTML: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%'
          },
          series: this.dataColumns
        }
      },
      graphOptionsDias() {
        return {
          chart: {
            type: 'column'
          },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          xAxis: {
              categories: this.categoriesList,
              crosshair: true,
              accessibility: {
                  description: 'Ataques a defensores de DDHH'
              }
          },
          legend: {
            enabled: true,
          },
          tooltip: {
            shared: true,
            useHTML: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%'
          },
          series: this.dataColumnsDias
        }
      },
    },     
}

</script>