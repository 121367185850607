<template>
<div class="card">
		<div class="row card-body">
		<div class="col-md-12">
      {{ categoriesGraph }}
      <highcharts class="packedbubble" :options="graphOptions" v-if="allAtaquesPie.length > 0"></highcharts>
		</div>
	</div> 
</div>
</template>

<style scoped>
  .item-ataques {
    display: inline-block;
    border-radius: 50%;
    background: #000;
    width: 5px;
    height: 5px;
  }

 .presi-name {
  font-weight: 800;
  border-bottom: dotted 1px; 
 } 
 
 .btn {
  border-radius: 0;
 }

 .leyenda {
    font-family: Arial, sans-serif;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .leyenda ul {
    list-style: none;
    padding: 0;
  }

  .leyenda li {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .leyenda .color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 3px;
  }

  .leyenda .opcion {
    display: inline-block;
    font-size: 16px;
    width: 16px;
    min-height: 16px;    
  }

</style>

<script>
  
  import { Chart } from 'highcharts-vue'
  import Highcharts from "highcharts"
  import more from 'highcharts/highcharts-more'
  import streamgraph from 'highcharts/modules/streamgraph'
  import { mapState } from 'vuex'  
  import { map, orderBy } from 'lodash'

  more(Highcharts)
  streamgraph(Highcharts)

export default {
    name: 'AtaquesSelect',
    props: {
      dataset: Array
    },
    components: {
      highcharts: Chart
    },
    data() {
      return {
        fieldSelected: 'ataque_name',
        categoriesList: [],
      }
    },
    mounted() {
       this.categoriesList = map(orderBy(this.graphOptions.series[0].data, 'y', 'desc'), 'name')
    },
    watch: {
      allAtaquesPie() {
        this.categoriesList = map(orderBy(this.graphOptions.series[0].data, 'y', 'desc'), 'name')
      }
    },
    computed: {      
      ...mapState({                
        allAtaquesPie: state => state.ataques.allAtaquesPie,        
      }),  
      graphOptions() {
        return {
          chart: {
            type: 'pie'
          },
          dataSorting: {
            enabled: true,
            sortKey: 'y'
          },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          legend: {
            enabled: true,
          },
          xAxis: {
            categories: this.categoriesList,
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f}%'
              }
            }
          },
          tooltip: {
            useHTML: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%'
          },
          series: [{
            name: "Ataques",
            dataSorting: {
              enabled: true
            },
            data: this.allAtaquesPie
          }]
        }
      },
    },     
}

</script>