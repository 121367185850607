import { createStore } from 'vuex'
import ataques from './modules/ataques'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    ataques
  },
  strict: debug
})

export default store