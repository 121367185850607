<template>
  <div class="row justify-content-center mt-3">
    <div class="col-12">
      <div>
        <highcharts :options="options" :updateArgs="[true, false]" ref="graph_sankey"></highcharts>
      </div>
    </div>    
  </div>
  </template>

<script>
 
  import { Chart } from 'highcharts-vue'
  import Highcharts from "highcharts"
  import sankey from 'highcharts/modules/sankey'
  
  sankey(Highcharts)

  export default {
    name: 'SankeyComparisons',
    props: {
      data: Array
    },
    computed: {
      series() {
        return [
            {
              type: 'sankey',
              name: 'Ataques',
              keys: ['from', 'to', 'weight'],
              data: this.data
            }
          ]
      },
      options() {
        return {
          chart: {
            height: '620',
            redraw: true,
            animation: false
          },
          title: {
              text: ''
          },
          subtitle: {
              text:''
          },
          credits: {
            enabled: true
          },
          series: this.series

        }
      }
    },
    components: {
      highcharts: Chart
    }
  }
</script>

<style>

.chart-custom text {
    font-size: 15px !important;
  }

path.highcharts-link{
  stroke: transparent !important;
  stroke-width: 0.5 !important;
}

.highcharts-figure,
.highcharts-data-table table {
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>