<template>  
  <div class="container-fluid">
    <Home/>
  </div>
</template>

<script>

import Home from '@/pages/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

</style>