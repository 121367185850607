import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/main.css"

import store from './store'

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App).use(store)

app.config.unwrapInjectedRef = true
app.config.unwrapObservable = true

app.mount('#app')