<template>
    <div class="container">
      <div class="row justify-content-center mt-3">
        <div class="col-md-12 col-12">
          <div class="card my-3">
            <div class="mapa-resumen card-body">
              <h3 class="my-3">Ataques por administración</h3>   
              
              <div class="row">
                <div class="col-md-3 col-4">
                  <div class="filtros">

                    <div class="card d-none d-md-block">
                      <div class="card-body ">
                        <span class="badge text-dark mb-3">Filtrar por presidencia</span>  
                        <div  v-for="(presidente, key) in allPresidentes" :key="key">
                          <div class="form-check form-switch">
                            <input v-model="selectedPresident" class="form-check-input" type="checkbox"  :value="presidente.name" id="flexCheckChecked">
                            <label class="text-small form-check-label mx-1" for="flexCheckChecked">{{ presidente.name }}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mob-filter d-block d-md-none">
                      <div class="card-body box-filter">
                        <span class="badge text-dark mb-3">Filtrar por presidencia</span>  
                        <div  v-for="(presidente, key) in allPresidentes" :key="key">
                          <div class="form-check form-switch">
                            <input v-model="selectedPresident" class="form-check-input" type="checkbox"  :value="presidente.name" id="flexCheckChecked">
                            <label class="text-small form-check-label mx-1" for="flexCheckChecked">{{ presidente.name }}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mt-3 d-none d-md-block">
                      <div class="card-body">
                        <span class="badge text-dark mb-3">Filtrar por ataques</span>  
                          <div v-for="(item, key) in AtaquesLista" :key="key">
                            <div class="form-check form-switch">
                              <input v-model="selectedAtaque" class="form-check-input" type="checkbox" :value="item.ataque_name" id="flexCheckAtaques">
                              <label class="form-check-label" for="flexCheckAtaques">
                                {{ item.ataque_name }}
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>

                    <div class="card mt-3 mob-filter d-block d-md-none">
                      <div class="card-body box-filter">
                        <span class="badge text-dark mb-3">Filtrar por ataques</span>  
                          <div v-for="(item, key) in AtaquesLista" :key="key">
                            <div class="form-check form-switch">
                              <input v-model="selectedAtaque" class="form-check-input" type="checkbox" :value="item.ataque_name" id="flexCheckAtaques">
                              <label class="form-check-label" for="flexCheckAtaques">
                                {{ item.ataque_name }}
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="col-md-9 col-8">
                  <div class="chart-custom" v-if="enableGraph">
                    <SankeyGraph :data="parseRanking" />
                  </div>
                  <div class="chart-custom" v-if="!enableGraph">Cargando ...</div>
                </div>
              </div>
     
            </div>
          </div>      
        </div>    
      </div>
    </div>
  </template>

<script>
 
  import { map, groupBy, kebabCase, filter, orderBy } from 'lodash'
  import { mapState } from 'vuex'

  import SankeyGraph from '@/components/SankeyGraph'

  export default {
    name: 'SankeyComparisons',
    props: {
      dataset: Array
    },
    components: {
      SankeyGraph
    },
    created(){
      this.enableGraph = false
      this.selectedPresident = this.allPresidentes.map(i => i.name)
      this.selectedAtaque = this.AtaquesLista.map(i => i.ataque_name)
    },
    data() {
      return {
        enableGraph: false,
        selectedPresident: [],
        selectedAtaque: [],
        fieldSelected: 'anio_aprob',
      }
    },
    methods: {
      kebabCase,
    },
    watch: {
      selectedPresident() {
        this.enableGraph = false
      },
      selectedAtaque() {
        this.enableGraph = false
      },
      parseRanking() {
        setTimeout(() => {
          this.enableGraph = true
        }, 300)
      }
    },
    mounted() {
      const values = {
        'from': `2000-01-01`,
        'to': `2025-01-01`,
      }

      this.$store.dispatch('ataques/getAllAtaques', values)
    },
    computed: {
        ...mapState({
            AtaquesLista: state => state.ataques.AtaquesLista,
            allPresidentes: state => state.ataques.presidentes,
            allAtaques: state => state.ataques.allAtaques
        }),
        filteredData() {
          return orderBy(filter(this.allAtaques, i => {
            if(this.selectedPresident.includes(i.presidente)) {
              if(this.selectedAtaque.includes(i.ataque)) {
                return i
              }
            }
          }), ['fecha'], ['asc'])
        },
        presidentData() {
          return groupBy(this.filteredData, 'presidente')
        },
        parseRanking() {
          let links= []

          map(this.presidentData, (items, presidente) => {
              map(groupBy(items, 'ataque'), (i, a) => {
              links.push(
                  {
                    id: kebabCase(presidente),  
                    from: (presidente),
                    to: a,
                    weight: i.length,
                  }
                )
              })
          })

          return links
        }
    }
  }
</script>

<style>

.filtros .mob-filter {
  font-size: 10px;  
 border: none; 
}

.filtros .box-filter {
  margin: 0;
  padding: 0;
  
}
</style>