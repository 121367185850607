import api from '../../api/api'

const list_presidentes = [
  {
    "name": "Alejandro Toledo",
    "from": "2001-08-01",
    "to": "2006-07-31"
  },
  {
    "name": "Alan García",
    "from": "2006-08-01",
    "to": "2011-07-31"
  },
  {
    "name": "Ollanta Humala",
    "from": "2011-08-01",
    "to": "2016-07-31"
  },
  {
    "name": "Pedro Pablo Kuczynsku",
    "from": "2016-01-01",
    "to": "2018-03-23"
  },
  {
    "name": "Martín Vizcarra",
    "from": "2018-03-24",
    "to": "2020-11-09"
  },
  {
    "name": "Manuel Merino",
    "from": "2020-11-10",
    "to": "2020-11-15"
  },
  {
    "name": "Francisco Sagasti",
    "from": "2020-11-16",
    "to": "2021-07-31"
  },
  {
    "name": "Pedro Castillo",
    "from": "2021-08-01",
    "to": "2022-12-10"
  },
  {
    "name": "Dina Boluarte",
    "from": "2022-12-11",
    "to": "2024-12-11"
  }
]

// initial state
const state = () => ({
  departamentoSelected: {
    "id": "all",
		"departamento": "Seleccionar departamento"
  },  
  aniosSelected: [`all`],
  showVictimas: false,
  allAtaquesGraph: [],
  allMapa: [],
  allHeridos: [],
  allCriminalizados: [],
  allDepartamentos: [],
  allByDepartamento: [],
  allAtaques: [],
  rankingData: [],
  AtaquesLista: [
    {
      "ataque_name": "Asesinato / Muerte",
      "color" : "#da8e00"
    },
    {
      "ataque_name": "Criminalización",
      "color" : "#00c1da"

    },
    {
      "ataque_name": "Herido",
      "color" : "#445320"
    }
  ],
  allGenders: [
    {
      "genero": "Masculino"
    },
    {
      "genero": "Femenino"
    },
    {
      "genero": "No Indica"
    }
  ],
  allbyMujeres: [],
  allAmbientales: [],
  allIndigenas: [],
  allEmpresarial: [],
  allbyCriminalizado:[],
  allbyDerecho: [],
  allAtaquesPie: [],
  distritosDepartamento: [],
  distritoSelected: {},
  presidentes: list_presidentes,
  presidentesSelected: []
})

// getters
const getters = {}

// actions
const actions = {
  getAllAtaques ({ commit }, values) {    
    api.getAtaques(ataques => {
      commit('setAllAtaques', ataques)
    }, values)
  },
  getRankingData({ commit }) {
    api.getAtaquesRanking( ataques => {
      commit('setRankingData', ataques)
    })
  },
  getAllByMujeres ({ commit }, values) {  
    values.chart = `mujeres`  
    api.getAtaquesMujeres(ataques => {
      
      commit('setAllByMujeres', ataques)
    }, values)
  },
  getAllAmbientales({ commit }, values) {
    values.chart = `ambientales`  
    api.getAtaquesMujeres(ataques => {
      
      commit('setAllAmbientales', ataques)
    }, values)
  },
  getAllIndigenas({ commit }, values) {
    values.chart = `indigenas`  
    api.getAtaquesMujeres(ataques => {
      commit('setAllIndigenas', ataques)
    }, values)
  },
  getAllEmpresarial({ commit }, values) {
    values.chart = `empresarial`  
    api.getAllEmpresarial(ataques => {
      commit('setAllEmpresarial', ataques)
    }, values)
  },
  getAllAtaquesPie({ commit }, values) {
    api.getAtaquesPie(ataques => {
      commit('setAllAtaquesPie', ataques)
    }, values)
  },
  getAtaquesByPresidente({ commit }, values) {

    let presidentes = []
    
    values.map(p => {
      let index = list_presidentes.map(i => i.name).indexOf(p)
      if( index > -1) {
        let presidente = list_presidentes[index]
        api.getAtaquesByPresidente(ataques => {
          presidente.ataques = ataques      
          presidentes.push(presidente)
        }, presidente)
      }
    })

    setTimeout(() => {
      commit('setAllPresidentes', presidentes)
    }, 350)
    
  },
  getAllDepartamentos ({ commit }) {    
    api.getAllDepartamentos(departamentos => {
      commit('setAllDepartamentos', departamentos)
    })
  },
  updateDepartamentoSelected ({ commit }, departamento) {
    commit('setDepartamentoSelected', departamento)

    api.getAllbyDepartamentos(distritos => {
      console.log(distritos)
      commit('setAllbyDepartamento', distritos)
    }, {departamento_id: departamento.departamento_id})
  },
  updateAllDepartamentos({ commit }, departamentos ) {
    commit('setAllDepartamentos', departamentos)
  },
  updateShowVictimas({ commit }, show_victimas ) {
    commit('setShowVictimas', show_victimas)
  },
  updateAniosSelected({ commit }, anios ) {
    commit('setAniosSelected', anios)
  },
  updateDistritosDepartamento({ commit }, ataques ) {
    commit('setDistritosDepartamento', ataques)
  },
  updateDistritoSelected({ commit }, distrito ) {
    commit('setDistritoSelected', distrito)
  }
}

// mutations
const mutations = {
  setAllPresidentes(state, presidentes){
    state.presidentesSelected = presidentes
  }, 
  setRankingData(state, ataques) {
    state.rankingData = ataques
  },
  setAllMapa(state, ataques){
    state.allMapa = ataques
  },  
  setAllAtaques(state, ataques){
    state.allAtaques = ataques
  },  
  setAllHeridos(state, ataques){
    state.allHeridos = ataques
  },  
  setAllCriminalizados(state, ataques){
    state.allCriminalizados = ataques
  },  
  setAllGenders(state, ataques){
    state.allGenders = ataques
  },  
  setAllByMujeres(state, ataques){
    state.allbyMujeres = ataques
  },
  setAllAmbientales(state, ataques) {
    state.allAmbientales = ataques
  },
  setAllIndigenas(state, ataques) {
    state.allIndigenas = ataques
  },
  setAllEmpresarial(state, ataques) {
    state.allEmpresarial = ataques
  },
  setAllAtaquesPie(state, ataques) {
    state.allAtaquesPie = ataques
  },
  setAllAtaquesGraph(state, ataques){
    state.allAtaquesGraph = ataques
  },  
  setAllbyDepartamento(state, ataques){
    state.allByDepartamento = ataques
  },  
  setAllDepartamentos(state, departamentos) {
    state.allDepartamentos = departamentos
  },  
  setDepartamentoSelected(state, departamento) {
    state.departamentoSelected = departamento
  },  
  setDistritosDepartamento(state, ataques){
    state.distritosDepartamento = ataques
  },
  setDistritoSelected(state, distrito){
    state.distritoSelected = distrito
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}