<template>  
  <div class="row">
      <div class="col-12 order-1 order-sm-0">
      <highcharts class="packedbubble" :options="graphOptions" v-if="allbyMujeres.length > 0"></highcharts>
      <b-spinner variant="primary" label="Spinning" v-if="allbyMujeres.length == 0"></b-spinner>      
    </div>
  </div>
</template>



<script>

  import { Chart } from 'highcharts-vue'
  import Highcharts from "highcharts"
  import more from 'highcharts/highcharts-more'
  import streamgraph from 'highcharts/modules/streamgraph'
  import { mapState } from 'vuex'
  import { map, orderBy } from 'lodash'

  more(Highcharts)
  streamgraph(Highcharts)

  export default {
    name: 'PieMujeres',  
    props: {
      dataset: Array
    },
    components: {
      highcharts: Chart
    },
    data() {
      return {
        fieldSelected: 'anio_aprob',
        categoriesList: []
      }
    },
    watch: {
      allbyMujeres() {
        this.categoriesList = map(orderBy(this.graphOptions.series[0].data, 'y', 'desc'), 'name')
      }
    },
    computed: {
      ...mapState({        
				allbyMujeres: state => state.ataques.allbyMujeres,
        AtaquesLista: state => state.ataques.AtaquesLista
      }),
      graphOptions() {
        return {
          chart: {
            type: 'pie'
          },
          dataSorting: {
            enabled: true,
            sortKey: 'y'
          },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          xAxis: {
            categories: this.categoriesList,
          },
          legend: {
            enabled: true,
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f}%'
              }
            }
          },
          tooltip: {
            useHTML: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%'
          },
          series: [{
            name: "Ataques a mujeres defensoras",
            dataSorting: {
              enabled: true
            },
            data: this.allbyMujeres
          }]
        }
      },          
    }   
  }
</script>