<template>
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-12">
				<div class="mapa-resumen my-5">
					<h3>REGISTRO DE ATAQUES A PERSONAS DEFENSORAS DE DERECHOS HUMANOS</h3>
				</div>			
			</div>
		</div>
		<div class="row">		
			<div class="col-md-12 col-12">
				<div class="intro">
					<p>Desde el año 2002, la Coordinadora Nacional de Derechos Humanos viene recopilando información sobre ataques a personas defensoras de derechos humanos en el Perú. Sí necesita información adicional escríbanos a info@derechoshumanos.pe</p>
				</div>
			</div>
		</div>
		
		<FiltersGraph />

	</div>
</template>

<script>

import FiltersGraph from '@/components/FiltersGraph'

export default {
	name:'ResumenGraficos',
	components: {
		FiltersGraph
	}
}
</script>
