<template>  
    <div class="row">
        <div class="col-12">
        <highcharts class="packedbubble" :options="graphOptions" v-if="allAmbientales.length > 0"></highcharts>
        <b-spinner variant="primary" label="Spinning" v-if="allAmbientales.length == 0"></b-spinner>        
        </div>  
    </div>
</template>
  
  
  
  <script>
  
    import { Chart } from 'highcharts-vue'
    import Highcharts from "highcharts"
    import more from 'highcharts/highcharts-more'
    import streamgraph from 'highcharts/modules/streamgraph'
    import { mapState } from 'vuex'
    import { map, orderBy } from 'lodash'

    more(Highcharts)
    streamgraph(Highcharts)
  
    export default {
      name: 'DefensoresAmbientales',  
      props: {
        dataset: Array
      },
      components: {
        highcharts: Chart
      },
      data() {
        return {
          fieldSelected: 'anio_aprob',
          categoriesList: []
        }
      },
      watch: {
        allAmbientales() {
          this.categoriesList = map(orderBy(this.graphOptions.series[0].data, 'y', 'desc'), 'name')
        }
      },
      computed: {
        ...mapState({        
          allAmbientales: state => state.ataques.allAmbientales
        }),
        graphOptions() {
          return {
            chart: {
              type: 'pie'
            },
            dataSorting: {
              enabled: true,
              sortKey: 'y'
            },
            credits: {
              enabled: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
              categories: this.categoriesList,
            },
            legend: {
              enabled: true,
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f}%'
                }
              }
            },
            tooltip: {
              useHTML: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f}%'
            },
            series: [{
              name: "Ataques a defensores",
              dataSorting: {
                enabled: true
              },
              data: this.allAmbientales
            }]
          }
        },          
      }   
    }
  </script>