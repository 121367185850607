<template>	
	<div class="interactive-container">
		<div id="map_container" class="map_container">
			<svg width="100%" :height="height" class="plan-vector-map" id="map_svg" ref="map_svg">
				<g ref="peru_departamentos"></g>
				<g ref="peru_distritos"></g>
				<g ref="labels_mapa"></g>
			</svg>
			<div v-if="!isLoading" class="loading-box">
				Cargando ... 
				<div class="spinner-grow text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<div class="spinner-grow text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div id="tooltipMap" class="tooltip"></div>
		</div>
		
	</div>
	
</template>

<style>

	.interactive-container {
		position: sticky;
		top: 0;
	}

	.map_container {
		position: relative;
	}

	.loading-box {
		position: absolute;
		top: 0;
		background: #ffffffd7;
		display: flex;
		z-index: 9;
		width: 100%;
		height: 100vh;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	.map_container  path {
		stroke: #e3e3e3;
		stroke-width: 1px;
		transition: opacity .2s ease-in-out;
		cursor: pointer;
	}

	.map_container  path:hover {
		stroke: rgb(255, 255, 255);
	}

	.map_container  path.inactive {
		opacity: 0.2;
	}

	.map_container h4 {
		font-size: 21px;
		font-weight: 900;
	}

	.map_container h6  {
		font-size: 16px;
		font-weight: 900;
	}

	.map_container .ataques-distritos {
		background: #ffffffe6;
		width: 100%;
		padding: 25px;
	}

	.map_container .circle-legend {
		background-color: #333;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-ataque {
		fill: transparent;
		stroke: #000;
		stroke-width: 1px;
		pointer-events: none;
	}

	.map_container .circle-legend-asesinato-muerte {
		background-color: #e41a1c;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-asesinato-muerte {
		stroke: #e41a1c;
		stroke-width: 1px;
	}

	.map_container .circle-legend-detencion-arbitraria {
		background-color: #377eb8;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-detencion-arbitraria {
		stroke: #377eb8;
		stroke-width: 1px;
	}

	.map_container .circle-legend-herido {
		background-color: #fdbf6f;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-herido{
		stroke: #fdbf6f;
		stroke-width: 1px;
	}

	.map_container .circle-legend-criminalizacion {
		background-color: #b2df8a;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-criminalizacion{
		stroke: #b2df8a;
		stroke-width: 1px;
	}

	.map_container .circle-legend-amenaza-de-muerte {
		background-color: #fb9a99;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}

	.map_container .circle-amenaza-de-muerte {
		stroke: #fb9a99;
		stroke-width: 1px;
	}

	.tooltip {
		position: fixed;
		width: 100%;
		max-width: 270px;
		pointer-events: none;
		background-color: #ffffffe5;
		padding: 10px;
	}

	.municipio-label {
		font-size: 13px;
		stroke: rgb(187, 89, 89);
		stroke-width: 1px;
	}

</style>

<script>
	import 'vue-good-table/dist/vue-good-table.css'	
	import axios from 'axios'
	import { countBy, find, filter, map, maxBy, groupBy, kebabCase, uniq, max } from 'lodash'
	import { geoMercator } from 'd3-geo'
	import { feature } from 'topojson'
	import numeral from 'numeral'
	import * as d3 from 'd3'
	import { mapState, mapActions } from 'vuex'

	export default {
		name: 'MapaInteractivo',		
		data() {
      return {
				isLoading: false,
				mapDepartamentos: {},
				distritoMap: {},
        openMenu: false,
        width: 720,
        height: 740,
        center: [],
        scale: 1500,
        distance: 0,
        bounds: [],
				initialScale: 0,
				initialCenter: [],
        center_device: [],
        zoomed: false,
        legendaValues: {},
				yearSelected: "2016",
				currentTooltip: {},
				tooltipDiv: {},
				data: [],
				columns: [
					{
						label: 'Fecha',
						field: 'fecha',          
						type: 'date',
						dateInputFormat: 'yyyy-MM-dd',
						dateOutputFormat: 'dd-MM-yyyy'
					},      
					{
						label: 'Victima',
						field: 'victima__persona'
					},
					{
						label: 'Ataque',
						field: 'ataque_text',          
					}
				],
				geoDistritos: [],
				scaleAtaques: {}
      }
    },
		mounted() {
			this.tooltipDiv = document.getElementById(`tooltipMap`)


			this.bounds = d3.geoBounds(this.peru_geojson)

			this.distance = d3.geoDistance(this.bounds[0], this.bounds[1])

			this.bounds = d3.geoBounds(this.peru_geojson)

			this.center = d3.geoCentroid(this.peru_geojson)

			this.initialCenter = d3.geoCentroid(this.peru_geojson)

			this.center_device = [this.width/1.9, this.height / 2]

			this.scale = (this.width * 1.2) / this.distance / Math.sqrt(1)

			this.initialScale = (this.width) / this.distance / Math.sqrt(1)

			this.renderMapa()
		},
		computed: {
			...mapState({        
				getAllDepartamentos: state => state.ataques.getAllDepartamentos,
				allDepartamentos: state => state.ataques.allDepartamentos,
				allAtaques: state => state.ataques.allMapa,
				aniosSelected: state => state.ataques.aniosSelected,
				departamentoSelected: state => state.ataques.departamentoSelected,
				allByDepartamento: state =>	state.ataques.allByDepartamento,
				distritoSelected: state =>	state.ataques.distritoSelected
      }),
			peru_geojson() {
				return require("@/data/mapas/perugeo.json")
			},
			projection () {
        return geoMercator()
          .translate([this.width/2, this.height/2])
          .scale(this.scale)
      },
			path () {
        return d3.geoPath().projection(this.projection)
      },
			distritosList() {
				return map(this.allByDepartamento, 'ubigeo')
			},
			distritosCount() {
				return map(groupBy(this.allByDepartamento, 'ubigeo'), (items, ubigeo) => {
					return {
						ubigeo: ubigeo,
						distrito: uniq(map(items, 'ubigeo_desc')).join(''),
						count: items.length,
						items: items
					}
				})
			},
			allByDistrito() {
				if(this.distritoSelected) {
					return filter(this.allByDepartamento, ['ubigeo', this.distritoSelected.ubigeo])
				}
				
				return {}
			}
		},
		watch: {
			departamentoSelected(value) {
				
				if(value.length > 2) {
					return false
				}

				if(value.id != `all`) {
					this.activate_departamentos()
				} else {
					this.activate_departamentos()
				}	

			},
			allDepartamentos() {
				this.isLoading = true
				this.fillMap()
			},
			distritoMap() {
				this.renderCircle()
				this.fillDistrito(this.distritosCount)
			},
			geoDistritos(v) {

				const bounds = d3.geoBounds(v)
				const distance = d3.geoDistance(bounds[0], bounds[1])
				const center = d3.geoCentroid(v)
				const scale = (this.width * 1.5) / distance / Math.sqrt(1)

				let base = d3.select(this.$refs['peru_departamentos'])
				const r = d3.interpolate(this.center, center)
				const s = d3.interpolate(this.scale, scale)

				base.selectAll('path.departamento-path')
				.transition()
				.duration(1000)
				.attrTween('d', (d) => {
					return (t) => {
					this.projection
						.scale(s(Math.pow(t,2)))                    
						.center(r(Math.pow(t,0.33)))
						.translate(this.center_device)                    
						
						this.path.projection(this.projection)

					return this.path(d)
					}
				})
				.on('start', () => {
					d3.selectAll(`path.distrito-path`).remove()
					this.removeLabels()						
					d3.selectAll('path.departamento-path').classed('inactive', false)
				})
				.on('end', () => {
					this.scale = scale
					this.center = center

					setTimeout(() => {
						this.renderDistritos(this.geoDistritos)
				}, 400);
					

				setTimeout(() => {
						this.renderCircle()
						this.fillDistrito(this.distritosCount)
						d3.selectAll('path.departamento-path').classed('inactive', true)
						this.renderLabels()
						this.isLoading = true
				}, 2000);
				})

			}
		},
		methods: {
			numeral,
			kebabCase,
			...mapActions('ataques', [
				'updateDepartamentoSelected',
				'updateDistritosDepartamento',
				'updateDistritoSelected'
			]),
			showDistrito(ubigeo) {
				const properties = find(this.geoDistritos.features, f => {
					if(f.properties.IDDIST == ubigeo.ubigeo) {
						return f
					}
				})

				let distrito = ubigeo

				if(properties)
					distrito.properties = properties.properties

				this.updateDistritoSelected(distrito)
			},
			activate_departamentos() {
				this.isLoading = false
				this.load_distrito()
			},
			load_distrito() {
				let file = kebabCase(this.departamentoSelected.departamento)
				axios
					.get(`/static/mapa-defensores/mapas/${file}.json`)
					.then(response => {
						setTimeout(() => {
							d3.selectAll('circle').remove()
							this.geoDistritos = feature(response.data, response.data.objects[file])
						}, 200)
					})
			},
			renderDistritos(geojson) {
				
				let base = d3.select(this.$refs['peru_distritos'])
				let center = this.center
				let scale = this.scale

				this.projection
				.translate(this.center_device)
				.center(center)
				.scale(scale)
				

				this.distritoMap = base.selectAll('path')
					.data(geojson.features)
					.enter()
					.append('path')
					.attr('d', this.path)
					.attr('id', d => {
						return `distrito-${d.properties.IDDIST}`
					})
					.attr('class', d => {
						return `distrito-path path-${d.properties.IDDIST}`
					})				
					.on("mouseover", (event, d) => {
							
						if(this.distritosList.indexOf(d.properties.IDDIST) > -1) {

							let selectDistrito = find(this.distritosCount, ['ubigeo', d.properties.IDDIST])

							this.tooltipDiv = document.getElementById(`tooltipMap`)

							this.tooltipDiv
								.style.opacity = .9

							let content = `<h6>${d.properties.DISTRITO} / ${d.properties.PROVINCIA} / ${d.properties.DEPARTAMEN }</h6><div class="mb-2 pb-2 border-bottom">Se han registrado <strong>${selectDistrito.count} ataques</strong> a defensores</div>`

							map(countBy(selectDistrito.items, 'ataque'), (val, ataque) => {
								content += `<div class="mb-2"><span class="circle-legend circle-legend-${kebabCase(ataque)}"></span> ${ataque}: <strong>${val}</strong-2 b></div>`
							})

							this.tooltipDiv.innerHTML = content
							this.tooltipDiv.style.left = event.clientX + "px"
							this.tooltipDiv.style.top = (event.clientY - 28) + "px"

						}

					})                  
					.on("mouseout", () => {       
							this.tooltipDiv     
								.style.opacity = 0
					})

				},
				renderCircle() {

					let base = d3.select(this.$refs['peru_distritos'])

					base.selectAll('path').each(d => {
						
						if(this.distritosList.indexOf(d.properties.IDDIST) > -1) {
							let selectDistrito = find(this.distritosCount, ['ubigeo', d.properties.IDDIST])
							let groupAtaques = groupBy(selectDistrito.items, 'ataque')
							let max_ataque = max(map(countBy(this.allByDepartamento, 'ataque'), (d) => d))
							let scaleCircle = d3.scaleLinear().domain([0, max_ataque]).range([5,30])

							map(groupAtaques, (i, a) => {
								base.append("circle")
									.attr("r", scaleCircle(i.length))
									.attr("class", `circle-ataque circle-${kebabCase(a)}`)
									.attr("fill","black")
									.attr("transform", () => {                 
										var p = this.projection(d3.geoCentroid(d))
										return "translate("+p+")";
									})
							})
							
						}
					})

				},
			hideTooltip() {
				this.currentTooltip = {}
			},
			renderMapa() {

				this.width = (document.querySelector(".map_container").offsetWidth * .75)

				let base = d3.select(this.$refs['peru_departamentos'])

				this.projection
					.translate(this.center_device)
					.center(this.center)
					.scale(this.scale)

				this.tooltipDiv.classList.add(`tooltip`)
				this.tooltipDiv.style.opacity = `tooltip`

				base.selectAll('path.departamento-path').remove()

				this.mapDepartamentos = base.selectAll('path')
					.data(this.peru_geojson.features)
					.enter()
					.append('path')
					.attr('d', this.path)
					.attr('id', d => {
						return `departamento-${d.properties.CCA_1}`
					})
					.attr('class', d => {
						return `departamento-path path-${d.properties.CCA_1}`
					})
					.on('click', (event, d) => {

						const dep = find(this.allDepartamentos, ['departamento_id', d.properties.CCA_1])

						this.updateDepartamentoSelected(
							{
								departamento_id: d.properties.CCA_1,
								departamento: d.properties.NOMBDEP,
								nro_ataques: dep.cnt_ataques
							}
						)
					})
					.on("mouseover", (event, d) => {
				
							this.tooltipDiv = document.getElementById(`tooltipMap`)

							this.tooltipDiv
								.style.opacity = .9

							this.tooltipDiv.innerHTML = `<h4>${d.properties.NOMBDEP}</h4><div> Click para explorar</div>`

							this.tooltipDiv.style.left = event.clientX + "px"
							this.tooltipDiv.style.top = (event.clientY - 28) + "px"

						})                  
					.on("mouseout", () => {       
							this.tooltipDiv     
								.style.opacity = 0
					})

					this.fillMap()
				
			},
			fillDistrito(countList) {
				
				if(countList.length > 0) {
					let maxAtaques = maxBy(countList, 'count')
					let scaleAtaques = d3.scaleLinear().domain([0, maxAtaques.count]).range(['#bdd7e7','#08519c'])
					
					this.distritoMap
						.attr('fill', d => {
							console.log(d)
							if(this.distritosList.indexOf(d.properties.IDDIST) > -1) {
								let selectDistrito = find(countList, ['ubigeo', d.properties.IDDIST])
								return scaleAtaques(selectDistrito.count)
							} else {
								return `#e3e3e3`
							}
						})
				}
			},
			fillMap() {
				if(this.allDepartamentos.length > 0) {

					let maxAtaques = maxBy(this.allDepartamentos, 'cnt_ataques')
						
					let scaleAtaques = d3.scaleLinear().domain([0, maxAtaques.cnt_ataques]).range(['#bdd7e7','#08519c'])

					this.mapDepartamentos
						.attr('fill', d => {
							let dep = find(this.allDepartamentos, 'departamento', d.properties.CCA_1)
							if(dep) {
								return `${scaleAtaques(dep.cnt_ataques)}`
							}
						})
				}

			},
			removeLabels() {
				let labelsG = d3.select(this.$refs['labels_mapa'])
				labelsG.selectAll('text').remove()
			},
			renderLabels() {
				
				let labelsG = d3.select(this.$refs['labels_mapa'])

        this.removeLabels()

        labelsG.selectAll('text')
          .data(this.peru_geojson.features)
          .enter()
          .append('text')
          .attr('transform', m => {
            if(!isNaN(this.path.centroid(m)[0]))
              return `translate(${this.path.centroid(m)})`
          })
          .attr('class', m => {
						return `departamento-label departamento-${m.properties.CCA_1}`
          })
          .attr('pointer-events', 'none')
          .text(d => {
						return d.properties.NOMBDEP
          })
			}
		}
	}
</script>