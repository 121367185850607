<template>	
	<div class="container">
		<div class="row justify-content-center mb-3">
			<div class="col-md-12 col-12">
				<div class="card">
					<div class="row card-body">
						<div class="col-12 mb-5">
							<div class="mapa-resumen">
								<h3> Ubicación de ataques a personas defensoras</h3>								
							</div>											
						</div>
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-3">
									<div class="">
									<div class="">										
										
										<div class="card">
											<div class="card-body">
												<span class="badge text-dark mb-3">Filtrar por departamentos</span>
											
												<div class="">
													<div v-for="dep in allDepartamentos.slice(0, 10)" :key="dep.id">
														<button v-if="dep.departamento != null && dep.departamento != 'No indica'" class="btn btn-link small text-small" @click="selectDepartamento(dep)">{{ dep.departamento }} ({{ dep.cnt_ataques }})</button>
													</div>
												</div>
												<div class="collapse" id="DepartamentoCollapse">
													<div v-for="dep in allDepartamentos.slice(10, allDepartamentos.length)" :key="dep.id">
														<button v-if="dep.departamento != null && dep.departamento != 'No indica'" class="btn btn-link small text-small" @click="selectDepartamento(dep)">{{ dep.departamento }} ({{ dep.cnt_ataques }})</button>
													</div>
												</div>

												<div class="border-top text-center pt-3">
													<button class="btn btn-dark" type="button" data-bs-toggle="collapse" data-bs-target="#DepartamentoCollapse" aria-expanded="false" aria-controls="DepartamentoCollapse">
														Mostrar todos
													</button>
												</div>

											</div>
										</div>										
										<button type="button" class="btn btn-light" v-if="departamentoSelected.id != 'all'" @click="resetDepartamento()">
											<i class="bi bi-x-lg"></i>
										</button>
									</div>

									

								</div>
								</div>
								
								<div class="col-12 col-md-9 container-departamento-interactive">									
									<MapaInteractivo />						
								</div>

							</div>
						</div>
					</div>
				</div>

			</div>			
		

		</div>

	</div>

	
	
</template>

<script>

import { map, groupBy, orderBy } from 'lodash'
import MapaInteractivo from '../components/MapaInteractivo.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'MapaPrincipal',
	components: {
		MapaInteractivo
	},
	data() {
		return {
			anioSelectedModel	: [],
			checked: true,
			showTable: false
		}
	},
	mounted() {
      const values = {
        'from': `2000-01-01`,
        'to': `2025-01-01`,
      }

      this.$store.dispatch('ataques/getAllDepartamentos', values)
    },
	computed: {
		...mapState({
			allDepartamentos: state => state.ataques.allDepartamentos,			
			allAtaques: state => state.ataques.allAtaques,
			allByDepartamento: state => state.ataques.allByDepartamento,
			departamentoSelected: state => state.ataques.departamentoSelected,
			showVictimas: state => state.ataques.showVictimas,
			distritosDepartamento: state => state.ataques.distritosDepartamento,
			distritoSelected: state => state.ataques.distritoSelected
		}),
		departamentos() {
			return orderBy(this.allDepartamentos,"departamento")
		},
		aniosAtaques() {
			return map(groupBy(this.allAtaques, 'anio'), (item, anio) => {
				return {
					value: anio,
					anio: anio,
					text: `${anio}`,
					nro_ataques: item.length
				}
			})
		}
	},
	watch: {
		anioSelectedModel(val) {
			this.updateAniosSelected(val)
		}
	},
	methods: {
		...mapActions('ataques', [
			'getAllDepartamentos',
			'getAllAtaques',
			'updateDepartamentoSelected',
			'updateShowVictimas',
			'updateAniosSelected',
			'updateAllDepartamentos',
			'updateDistritoSelected'
		]),
		showDistrito(ubigeo) {
			this.updateDistritoSelected(ubigeo)
		},
		active_link(id) {
			if(this.departamentoSelected.id == id) {
				return `active-link`
			}
		},
		updateDepartamentos() {			
			this.updateAllDepartamentos(orderBy(this.allDepartamentos, ['departamento'], ['asc']))
		},
		selectDepartamento(dep){
			this.updateDepartamentoSelected(dep)
		},
		resetDepartamento(){
			this.showTable = false

			this.updateDepartamentoSelected({
				"id": "all",
				"departamento": "Seleccionar departamento"
			})
			this.updateShowVictimas(false)
		},
		mostrarVictimas(){
			if(this.showVictimas==false){
				
				this.updateShowVictimas(true)
			}else {
				this.updateShowVictimas(false)
			}			
			
		},
		showMore() {
        this.visibleDepartamentosCount += 10
    }
	}	
	
}
</script>

<style>
ul {
	margin: 0;
	padding: 0;
}


li {
	list-style: none;
	font-size: 19px;
}


</style>