/**
 * Client-server processing
 */
import axios from 'axios'
import { filter } from 'lodash'

let victimas_list = []  

//['#a50026','#d73027','#f46d43','#fdae61','#fee08b','#ffffbf','#d9ef8b','#a6d96a','#66bd63','#1a9850','#006837']

const site_url = `https://defensores.pe`

export default {
  getAtaquesByPresidente(cb, values) {
    // http://127.0.0.1:8000/ataques/?format=json&fecha_after=2013-01-01&fecha_before=2015-01-01&ataque=asesinato_muerte

    axios
    .get(`${ site_url }/ataques/?format=json&fecha_after=${values.from}&fecha_before=${values.to}`)
    .then(response => {
      setTimeout(() => cb(response.data), 250)
    })

  },
  getAtaques(cb, values) {
    axios
    .get(`${ site_url }/ataques/?format=json&fecha_after=${values.from}&fecha_before=${values.to}`)
    .then(response => {
      setTimeout(() => cb(response.data), 250)
    })

  },
  getAtaquesRanking(cb) {

    axios
    .get(`${ site_url }/ranking`)
    .then(response => {
      setTimeout(() => cb(response.data), 250)
    })

  },
  getAllDepartamentos(cb) {

    axios
      .get(`${ site_url }/departamentos/?format=json`)
      .then(response => {
        
        let departamentos = response.data

        setTimeout(() => cb(departamentos), 100)
      })

  },
  getAtaquesPie(cb, values) {
    axios
      .get(`${ site_url }/mujeres/?format=json&fecha_after=${values.from}&fecha_before=${values.to}`)
      .then(response => {
        setTimeout(() => cb(response.data), 250)
      })
  },
  getAtaquesMujeres(cb, values) {

    axios
      .get(`${ site_url }/mujeres/?format=json&chart=${values.chart}&fecha_after=${values.from}&fecha_before=${values.to}`)
      .then(response => {
        setTimeout(() => cb(response.data), 250)
      })
  },
  getAllEmpresarial(cb, values) {
    axios
      .get(`${ site_url }/empresarial/?format=json&chart=${values.chart}&fecha_after=${values.from}&fecha_before=${values.to}`)
      .then(response => {
        setTimeout(() => cb(response.data), 250)
      })
  },
  getAllMapa(cb,) {
    axios
      .get(`${ site_url }/admin/registro/mapa_regiones`)
      .then(response => {
        let perpetradores = response.data
        setTimeout(() => cb(perpetradores), 100)
      })
  },
  getAllbyDepartamento(cb, { departamento_id }) {
    if(victimas_list.length > 0) {
      let filtered = filter(victimas_list, ['departamento_id', `${departamento_id}`])
      setTimeout(() => cb(filtered), 100)
    }
  },
  getAllbyDepartamentos(cb, { departamento_id }) {

    axios
      .get(`${ site_url }/distritos/?departamento_id=${departamento_id}&format=json`)
      .then(response => {
        setTimeout(() => cb(response.data), 300)
    })
  }
}
