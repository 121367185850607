<template>  
  <div class="row justify-content-center">
    <div class="col-md-12 col-12">
      <div class="card my-3">
        <div class="card-body">
        <div class="row">        
        <div class="col-md-12">
          <div class="float-end my-3">
            <div class="input-group flex-nowrap">
            <input type="hidden"  value="01/01/2020">
            <div class="btn input-group-text" id="addon-wrapping">Filtrar por Fechas</div>
            <input v-model="date_from" class="form-control" name="fecha_start" type="date" value="01/01/2020">
            <input v-model="date_to" class="form-control" name="fecha_end" type="date" value="01/01/2024">
            <button @click="filter_charts()" class="btn btn-secondary">Mostrar</button>
          </div>
          </div>
        </div>
        <div class="col-12">          
          <div>
            <ul class="nav nav-tabs filter-navs" id="FilterTabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Todos los ataques</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="-tab" data-bs-toggle="tab" data-bs-target="#mujeres" type="button" role="tab" aria-controls="mujeres" aria-selected="false">Mujeres Defensoras</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="ambiental-tab" data-bs-toggle="tab" data-bs-target="#ambiental" type="button" role="tab" aria-controls="ambiental" aria-selected="false">Defensores Ambientales y Derechos Indigenas</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="empresarial-tab" data-bs-toggle="tab" data-bs-target="#empresarial" type="button" role="tab" aria-controls="empresarial" aria-selected="false">Violencia Empresarial</button>
              </li>              
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><AtaquesSelect/></div>
              <div class="tab-pane fade" id="mujeres" role="tabpanel" aria-labelledby="mujeres-tab"><PieMujeres/></div>
              <div class="tab-pane fade" id="ambiental" role="tabpanel" aria-labelledby="ambiental-tab"><DefensoresAmbientales/></div>
              <div class="tab-pane fade" id="empresarial" role="tabpanel" aria-labelledby="empresarial-tab"><ViolenciaEmpresarialChart/></div>               
            </div>
          </div>                    
        </div>        
      </div>
      </div>
      </div>
           
    </div>    
  </div>
</template>

<script>

import AtaquesSelect  from '@/components/AtaquesSelect.vue'
import DefensoresAmbientales  from '@/components/DefensoresAmbientales.vue'
import PieMujeres from '@/components/PieMujeres.vue'
import ViolenciaEmpresarialChart from '@/components/ViolenciaEmpresarialChart.vue'


  export default {
    name: 'FiltersGraph',
    components: {      
      AtaquesSelect,
      PieMujeres,
      ViolenciaEmpresarialChart,
      DefensoresAmbientales
    },
    data() {
      return {
        date_from: '2000-01-01',
        date_to: '2024-01-01',
        values: {
          'from': this.date_from,
          'to': this.date_to
        }
      }
    },
    methods: {
      filter_charts() {
        this.values = {
          'from': this.date_from,
          'to': this.date_to
        }
        this.$store.dispatch('ataques/getAllAtaquesPie', this.values)
        this.$store.dispatch('ataques/getAllByMujeres', this.values)
        this.$store.dispatch('ataques/getAllAmbientales', this.values)
        this.$store.dispatch('ataques/getAllIndigenas', this.values)
        this.$store.dispatch('ataques/getAllEmpresarial', this.values)
      }
    },
    mounted() {
      this.values = {
        'from': this.date_from,
        'to': this.date_to
      }

      this.$store.dispatch('ataques/getAllAtaquesPie', this.values)
        this.$store.dispatch('ataques/getAllByMujeres', this.values)
        this.$store.dispatch('ataques/getAllAmbientales', this.values)
        this.$store.dispatch('ataques/getAllIndigenas', this.values)
        this.$store.dispatch('ataques/getAllEmpresarial', this.values)
    }
  }
</script>