<template>	
<div class="row">
	<div class="col-md-12 col-12">
		<ResumenGraficos/>
		<RankingAsesinatos />
		<SankeyComparisons/>
		<MapaPrincipal/>
	</div>
</div>	
</template>


<script>


import ResumenGraficos  from '@/components/ResumenGraficos'
import RankingAsesinatos from '@/components/RankingAsesinatos'
import SankeyComparisons  from '@/components/SankeyComparisons'
import MapaPrincipal from '@/components/MapaPrincipal'

export default {
	name: 'MyHome',
	components: {
		ResumenGraficos,
		RankingAsesinatos,
		MapaPrincipal,
		SankeyComparisons
	}
}
</script>
